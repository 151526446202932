import axios from "axios";

export default {
  methods: {
    async uploadFilesAttach(apiPath, files, id) {
      const clearLinksFiles = files.filter((fileObj) => fileObj.file);
      if (clearLinksFiles.length) {
        let formDataFiles = new FormData();
        for (const fileObj of clearLinksFiles) {
          formDataFiles.append("files", fileObj.file);
        }
        await axios.post(
          this.$store.getters.apiV7 + `/attach/${apiPath}/${id}`,
          formDataFiles,
          {
            headers: {
              authorization: this.$store.getters.adminPanelHeaderAuth,
            },
          }
        );
      }
    },
    async deleteFileAttach(apiPath, id) {
      await axios.delete(
        this.$store.getters.apiV7 + `/attach/${apiPath}/${id}`,
        {
          headers: {
            authorization: this.$store.getters.adminPanelHeaderAuth,
          },
        }
      );
    },
    createImageURL(val) {
      if (val?.includes("http")) {
        return val;
      }
      return this.$store.getters.baseURL + val;
    },
    validateEmail(mail) {
      const reg = /[^@ \t\r\n]+@[^@ \t\r\n]+\.[^@ \t\r\n]+/;
      return reg.test(mail);
    },
    formatPhoneNumber(phoneNumber) {
      if (!phoneNumber) return "";
      return phoneNumber.replace(
        /(\d)(\d{3})(\d{3})(\d{2})(\d{2})/,
        "+$1 ($2) $3 $4-$5"
      );
    },
    declinationOfWords(value, words) {
      value = Math.abs(value) % 100;
      var num = value % 10;
      if (value > 10 && value < 20) return words[2];
      if (num > 1 && num < 5) return words[1];
      if (num == 1) return words[0];
      return words[2];
    },
    getInformationImage(image) {
      return new Promise((resolve, reject) => {
        try {
          const img = new Image();
          img.src = URL.createObjectURL(image);
          img.onload = () => {
            const newObj = {
              width: img.width,
              height: img.height,
            };
            resolve(newObj);
          };
        } catch (e) {
          reject(e);
        }
      });
    },
    wordTruncation(word, from, to) {
      if (word && to && word.length > to) {
        word = word.slice(from, to);
        word += "...";
      }
      return word;
    },

    async involvePagination(paginationSupply, store) {
      const pagination = paginationSupply.paginationParam;
      const reqParams = new URLSearchParams(paginationSupply.requestParams);
      let items = paginationSupply.itemsParam;
      if (pagination.hasNextPage) {
        try {
          const response = await fetch(
            `${store.apiV7}/${paginationSupply.api}?${reqParams}&page=${pagination.page}&take=${pagination.take}`,
            {
              method: "GET",
              headers: {
                authorization: store.adminPanelHeaderAuth,
              },
            }
          );
          const data = await response.json();
          if (paginationSupply.isScroll) {
            items = [...items, ...data.data];
            pagination.page = data.pagination.page;
          } else {
            items = data.data;
          }
          if (data.pagination.pageCount === data.pagination.page) {
            pagination.hasNextPage = false;
          }
          return { itemsNew: items, paginationNew: pagination };
        } catch (error) {
          console.error(error);
        }
      }
      return {
        itemsNew: paginationSupply.itemsParam,
        paginationNew: pagination,
      };
    },

    createDebounce() {
      let timeout = null;
      return (fnc, delayMs) => {
        clearTimeout(timeout);
        timeout = setTimeout(() => {
          fnc();
        }, delayMs || 500);
      };
    },
    async takeFirstFrameVideo(videoUrl, fileName = "frame.jpg") {
      return new Promise((resolve, reject) => {
        const video = document.createElement("video");
        video.src = videoUrl;
        video.crossOrigin = "anonymous";
        video.muted = true;
        video.autoplay = false;
        video.playsInline = true;

        video.addEventListener("loadeddata", () => {
          video.currentTime = 0.1;
        });

        video.addEventListener("seeked", () => {
          const canvas = document.createElement("canvas");
          canvas.width = video.videoWidth;
          canvas.height = video.videoHeight;
          const ctx = canvas.getContext("2d");
          ctx.drawImage(video, 0, 0, canvas.width, canvas.height);

          canvas.toBlob(
            (blob) => {
              if (blob) {
                const file = new File([blob], fileName, { type: "image/jpeg" });
                resolve(file);
              } else {
                reject(new Error("Ошибка создания Blob"));
              }
            },
            "image/jpeg",
            0.9
          );
        });

        video.addEventListener("error", (e) => reject(e));
      });
    },

    containsTest(str) {
      return str.toLowerCase().includes("test");
    },
  },
};
