<template>
 <Transition name="fade">
    <v-alert class="success-alert" v-if="this.$store.state.success" type="success" min-height="72px">
      <template v-slot:prepend>
        <v-icon> mdi-check-circle </v-icon>
      </template>
       {{ this.$store.state.successText }}
    </v-alert>
  </Transition>
</template>
<script>
export default {
  data: () => ({
  
  }),
  methods: {},
  computed:{
     
  },
  mounted(){
  },
};
</script>

<style scoped lang="scss">
.fade-enter-active,
.fade-leave-active {
  transition: all 0.3s;
  max-height: 100vh;
}
.fade-enter-from,
.fade-leave-to {
  opacity: 0;
  max-height: 0px;
}
.success-alert {
  z-index: 10000;
  position: fixed;
  top: 0px;
  width: 100vw;
  border-radius: 0px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  :deep(.v-alert__prepend) {
    align-self: unset;
  }
  i {
    &::before {
      font-size: 24px !important;
    }
  }
}
@media screen and (max-width: 640px) {
  .success-alert {
    font-size: 16px !important;
    height: 70px;
  }
}
</style>